








































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ValidatorMixin from '@/modules/shared/mixins/ValidatorMixin'

import PublicationModel from '@/modules/shared/entities/form-models/Publication'

import ConfirmDialog from '@/modules/shared/components/ConfirmDialog.vue'
import DatePicker from '@/modules/shared/components/DatePicker.vue'
import DeleteIcon from '@/modules/shared/components/icons/DeleteIcon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

const AuthModule = namespace('auth')
const AdminPublicationModule = namespace('admin_publication')

@Component({
  components: {
    ConfirmDialog,
    DatePicker,
    DeleteIcon,
    PrimaryButton,
  },
})
export default class PublicationForm extends Mixins(ValidatorMixin) {
  @AdminPublicationModule.Getter
  private readonly formModel!: PublicationModel|null

  @AdminPublicationModule.Mutation
  private readonly setFormModel!: (formModel: PublicationModel|null) => Promise<void>

  @AdminPublicationModule.Action
  private readonly removePublication!: (id: number) => Promise<void>

  @AdminPublicationModule.Action
  private readonly savePublication!: (formModel: PublicationModel|null) => Promise<void>

  @AuthModule.Getter
  private readonly isAdmin!: boolean

  @Watch('formModel')
  updateModel(newValue: PublicationModel|null = null) {
    this.model = (newValue === null) ? newValue : newValue?.clone()
  }

  private model: PublicationModel|null = null

  private language = 'pt'

  private confirmDialog = false

  private get types() {
    return [
      {
        text: this.$i18n.t('admin.publication.types.publication'),
        value: 'PUBLICATION',
      },
      {
        text: this.$i18n.t('admin.publication.types.video'),
        value: 'VIDEO',
      },
      {
        text: this.$i18n.t('admin.publication.types.pop'),
        value: 'POP',
      },
    ]
  }

  private get isOpen(): boolean {
    return this.model !== null
  }

  private set isOpen(open: boolean) {
    if (open === false) {
      this.setFormModel(null)
      this.language = 'pt'
    }
  }

  private get isEditing(): boolean {
    return !!this.model?.id
  }

  private deletePublication(id: number) {
    this.removePublication(id)
    this.confirmDialog = false
  }

  private async submit() {
    if (!this.validate()) return

    try {
      await this.savePublication(this.model)

      this.reset()
    } catch (err) {
      console.error(err)
    }
  }
}
