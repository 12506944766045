var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":""}},[_c('v-data-table',{staticClass:"--striped",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","options":_vm.options,"server-items-length":_vm.publications.length,"headers":_vm.headers,"items":_vm.publications},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(item.date))+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"d-block text-truncate",staticStyle:{"max-width":"310px"}},[_vm._v(" "+_vm._s(item.link)+" ")])]}},{key:"item.titlePt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(" "+_vm._s(item.titlePt)+" ")])]}},{key:"item.titleEn",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(" "+_vm._s(item.titleEn)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.editPublication(item.id)}}},[_c('icon',{attrs:{"left":"","outlined":"","name":"create","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('shared.actions.edit'))+" ")],1)]}},{key:"footer",fn:function(){return [(_vm.pageable)?_c('pagination',{staticClass:"pagination",attrs:{"value":_vm.pageable.currentPage,"length":_vm.pageable.totalPages},on:{"input":_vm.pageChange}}):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }